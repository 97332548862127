<template>
  <div>
    <div class="abc" :style="{ background: slideData.background_color }">
      <div class="insurance-wrapper">
        <div class="title">
          <div
            v-if="slideData.include_copy"
            class="insurance-title"
            v-html="slideData.slide_text"
          ></div>
        </div>
        <div class="insurance">
          <div class="insurance-body">
            <span
              class="title-insurance-padding"
              v-html="slideData.insurance_text"
            ></span>
            <div ref="insuranceScroll" class="insurance-scroll">
              <div
                class="insurance-plans"
                v-for="data in getFacilityInsurance"
                :key="data.id"
              >
                <div class="insurance-coverage">
                  <span class="coverage-span">
                    ${{ toLocaleString(data.coverage_amount) }}
                  </span>
                  <span class="coverage-text">Coverage</span>
                </div>
                <div class="insurance-monthly">
                  <span class="monthly-span">${{ data.monthly_premium }}</span>
                  <span class="monthly-span-text">/month</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

function scrollBy(time, element) {
  let target = element.scrollHeight
  // const elementInitialScrollPosition = element.scrollTop
  var currentPosition = element.scrollTop
  var scrollInterval = target / time
  var intervalTime = 2
  setInterval(function () {
    currentPosition = currentPosition + scrollInterval * intervalTime
    element.scrollTop = currentPosition
    if (currentPosition >= target) {
      // element.scrollTop = elementInitialScrollPosition
      // currentPosition = element.scrollTop
      currentPosition = 0
    }
  }, intervalTime)
}

export default {
  name: 'insurance',
  props: {
    slideData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      facilityInsurances: (state) => (state.facilityInsurances || []).filter(({ enabled, deleted }) => (enabled && !deleted)),
    }),
    getFacilityInsurance() {
      const insuranceObj = this.facilityInsurances
      return insuranceObj
    },
  },
  mounted() {
    this.$nextTick(() => {
      scrollBy(15000, this.$refs['insuranceScroll'])
    })
  },
  methods: {
    toLocaleString(number) {
      return Number(number).toLocaleString()
    },
  },
}
</script>

<style scoped>
.abc {
  position: fixed;
  /* font-size: 60px; */
  height: 50%;
  color: white;
  bottom: 0;
  width: 100%;
}
.insurance-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* height: 300px; */
}
.title {
  width: 50%;
  /* padding-top: 106px; */
  padding-left: 50px;
  line-height: 1.1;
}
.insurance {
  width: 50%;
  padding: 72px 100px 140px 185px;
}

/* .insurance-text {
  text-align: left;
  font-size: 70px;
  color: white;
  width: 60%;
  padding-left: 10%;
} */

.title-insurance-padding {
  font-size: 60px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.insurance-title {
  font-size: 70px;
  text-align: left;
  padding-left: 60px;
  /* display: flex; */
  /* font-weight: 700; */
  color: white;
  width: 90%;
  margin-left: 5px;
  margin-top: 105px;
}
.insurance-scroll {
  overflow-y: scroll;
  height: 300px;
  margin-top: 30px;
  /* width: 80%; */
  /* justify-content: space-between; */
  font-size: 45px;
}
.insurance-body {
  text-align: left;
  /* max-height: 30vh !important; */
  /* padding: 140px; */
  font-size: 50px;
  color: #ffffff;
  height: 100%;
}

.insurance-plans {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-clip: padding-box;

  /* border-bottom: 2px solid white; */
  padding-top: 5px;
  padding-bottom: 5px;
}

.insurance-coverage {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  font-size: 40px;
}

/* .insurance-monthly {
  width: 50%;
  display: flex;
} */

.coverage-span {
  /* margin-right: 10px; */
  font-family: 'Gotham';
  font-size: 40px;
  /* min-width: 145px; */
  text-align: left;
  font-weight: bolder;
}
.coverage-text {
  padding-left: 10px;
}
.monthly-span {
  /* padding-right: 20px; */
  max-width: 130px;
  text-align: right;
  font-weight: bold;
  font-size: 55px;
}
.monthly-span-text {
  font-size: 40px;
}
.insurance-scroll::-webkit-scrollbar {
  display: none;
}
</style>
